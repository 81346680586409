import React from "react";
import Dashboard from "app/pages/dashboard/Dashboard";
import Page from "@jumbo/shared/Page";

const dashboardRoutes = [
    {
        path: "/dashboards",
        element: <Page component={Dashboard} />,
    },
];

export default dashboardRoutes;
