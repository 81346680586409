import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import BranchListItem from "./BranchListItem";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import styled from "@emotion/styled";
import { branchService } from "app/services/branch-service";
import BranchForm from "./BranchForm";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: "none",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant="filled"
            {...props}
            sx={{ zIndex: 20000 }}
        />
    );
});

const ListBranch = () => {
    const { t } = useTranslation();
    const [branchList, setBranchList] = useState([]);
    const { hideDialog, showDialog } = useJumboDialog();
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");

    useEffect(() => {
        branchService.getList().then((data) => {
            setBranchList(data?.branches);
        });
    }, []);

    const hideDialogAndRefreshBranchList = React.useCallback(() => {
        //fetch updated branch list
        branchService.getList().then((data) => {
            setBranchList(data?.branches);
            setAlertType("success");
            setAlertMessage("Branch Removed Successfully !");
            setAlertOpen(true);
            hideDialog();
        });
    }, [hideDialog]);

    const handleRemoveBranch = (branchId) => {
        showDialog({
            variant: "confirm",
            title: "Are you sure about deleting this branch?",
            content: "You won't be able to recover this branch later",
            onYes: () =>
                branchService.remove({ id: branchId }).then(() => {
                    hideDialogAndRefreshBranchList();
                }),
            onNo: hideDialog,
        });
    };

    const handleAlertClose = (event, reason) => {
        setAlertOpen(false);
    };

    const handleBranchAdd = React.useCallback(
        (branchId, mode) => {
            if (mode === "edit") {
                setAlertType("success");
                setAlertMessage("Branch Udpated Successfully !");
            } else {
                setAlertType("success");
                setAlertMessage("Branch Added Successfully !");
            }
            setAlertOpen(true);

            //fetch updated branch list
            branchService.getList().then((data) => {
                setBranchList(data?.branches);
                hideDialog();
            });
        },
        [hideDialog]
    );

    const showAddEditBranchDialog = React.useCallback(
        (event, branch) => {
            if (branch) {
                showDialog({
                    title: "Update Branch",
                    content: (
                        <BranchForm onSave={handleBranchAdd} branch={branch} />
                    ),
                });
            } else {
                showDialog({
                    title: "Add New Branch",
                    content: <BranchForm onSave={handleBranchAdd} />,
                });
            }
        },
        [handleBranchAdd, showDialog]
    );

    return (
        <JumboCardQuick
            title={t("pages.title.branchList")}
            wrapperSx={{ p: 0 }}
            action={
                <Fab
                    size="small"
                    color="primary"
                    aria-label="add"
                    onClick={showAddEditBranchDialog}
                >
                    <AddIcon />
                </Fab>
            }
        >
            <JumboScrollbar
                autoHeight={true}
                autoHideTimeout={4000}
                autoHeightMin={690}
                autoHide={true}
                hideTracksWhenNotNeeded
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell sx={{ pl: 3 }}>
                                Name
                            </StyledTableCell>
                            <StyledTableCell>Address</StyledTableCell>
                            <StyledTableCell>Mobile 1</StyledTableCell>
                            <StyledTableCell>Mobile 2</StyledTableCell>
                            <StyledTableCell>Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {branchList?.map((branch) => (
                            <BranchListItem
                                item={branch}
                                key={branch.id}
                                handleItemClick={() => {
                                    showAddEditBranchDialog("", branch);
                                }}
                                handleRemoveBranch={handleRemoveBranch}
                            />
                        ))}
                    </TableBody>
                </Table>
            </JumboScrollbar>
            <Snackbar
                open={alertOpen}
                onClose={handleAlertClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert
                    onClose={handleAlertClose}
                    severity={alertType}
                    sx={{ width: "100%" }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
        </JumboCardQuick>
    );
};

export default ListBranch;
