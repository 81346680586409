const formatPayload = (data, added_by) => {
    return {
        ...data,
        added_by: added_by,
        branch_id: data?.branch_id?.id,
        customer_id: data?.customer_id?.id,
    };
};

export { formatPayload };
