import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Pagination,
    Typography,
    Grid,
    Divider,
    Button,
} from "@mui/material";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ExpenseListItem from "./ExpenseListItem";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import styled from "@emotion/styled";
import { expenseService } from "app/services/expense-service";
import ExpenseForm from "./ExpenseForm";
import { expenseCategoryService } from "app/services/expenseCategory-service";
import { branchService } from "app/services/branch-service";
import { Form, Formik } from "formik";
import JumboAutoCompleteField from "@jumbo/components/JumboFormik/JumboAutoCompleteField";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: "none",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant="filled"
            {...props}
            sx={{ zIndex: 20000 }}
        />
    );
});

const initialValues = {
    branch_id: null,
    category_id: null,
    year: null,
    month: null,
};

const ListExpense = () => {
    const { t } = useTranslation();
    const [expenseList, setExpenseList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [expenseCategoryList, setExpenseCategoryList] = useState([]);
    const { hideDialog, showDialog } = useJumboDialog();
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [limit, setLimit] = useState(13);
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(0);
    const [totalCount, setTotalCount] = React.useState(0);
    const [branch, setBranch] = useState(null);
    const [expenseCategory, setExpenseCategory] = useState(null);
    const [year, setYear] = useState("");
    const [month, setMonth] = useState(null);

    const authData = useJumboAuth();

    const onSubmitFilter = (data, { setSubmitting }) => {
        expenseService
            .getList({
                limit,
                offset,
                filter: {
                    branch_id: data.branch_id,
                    category_id: data.category_id,
                    year: data.year,
                    month: data.month,
                },
            })
            .then((data) => {
                setSubmitting(false);
                setExpenseList(data?.expenses);
                setTotalCount(data?.totalCount);

                //set total pages count
                const quotient = Math.floor(data?.totalCount / data?.limit);
                const reminder = data?.totalCount % data?.limit;
                setTotalPages(quotient + (reminder === 0 ? 0 : 1));

                //set current page
                setCurrentPage(1);
            });
    };

    const onPaginationChange = (event, value) => {
        expenseService
            .getList({
                limit,
                offset: limit * (value - 1),
                filter: {
                    branch_id: branch?.id || null,
                    category_id: expenseCategory?.id || null,
                    year: year || null,
                    month: month || null,
                },
            })
            .then((data) => {
                setExpenseList(data?.expenses);
                setTotalCount(data?.totalCount);
                setOffset(data?.offset);
                setLimit(data?.limit);

                //set total pages count
                const quotient = Math.floor(data?.totalCount / data?.limit);
                const reminder = data?.totalCount % data?.limit;
                setTotalPages(quotient + (reminder === 0 ? 0 : 1));

                //set current page
                setCurrentPage(value);
            });
    };

    useEffect(() => {
        expenseService.getList({ limit, offset }).then((data) => {
            setExpenseList(data?.expenses);
            setTotalCount(data?.totalCount);
            setOffset(data?.offset);
            setLimit(data?.limit);

            //get total pages count
            const quotient = Math.floor(data?.totalCount / data?.limit);
            const reminder = data?.totalCount % data?.limit;
            setTotalPages(quotient + (reminder === 0 ? 0 : 1));

            //get current page
            setCurrentPage(1);

            //fetch branch list
            branchService.getList().then((branchListData) => {
                setBranchList(branchListData?.branches);

                //fetch expense category list
                expenseCategoryService.getList().then((data) => {
                    setExpenseCategoryList(data?.expenseCategories);
                });
            });
        });
    }, []);

    const hideDialogAndRefreshExpenseList = React.useCallback(() => {
        //fetch updated expense list
        expenseService.getList().then((data) => {
            setExpenseList(data?.expenses);
            setAlertType("success");
            setAlertMessage("Expense Removed Successfully !");
            setAlertOpen(true);
            hideDialog();
        });
    }, [hideDialog]);

    const handleRemoveExpense = (expenseId) => {
        showDialog({
            variant: "confirm",
            title: "Are you sure about deleting this expense?",
            content: "You won't be able to recover this expense later",
            onYes: () =>
                expenseService.remove({ id: expenseId }).then(() => {
                    hideDialogAndRefreshExpenseList();
                }),
            onNo: hideDialog,
        });
    };

    const handleAlertClose = (event, reason) => {
        setAlertOpen(false);
    };

    const handleExpenseAdd = React.useCallback(
        (expenseId, mode) => {
            if (mode === "edit") {
                setAlertType("success");
                setAlertMessage("Expense Udpated Successfully !");
            } else {
                setAlertType("success");
                setAlertMessage("Expense Added Successfully !");
            }
            setAlertOpen(true);

            //fetch updated expense list
            expenseService.getList().then((data) => {
                setExpenseList(data?.expenses);
                hideDialog();
            });
        },
        [hideDialog]
    );

    const showAddEditExpenseDialog = React.useCallback(
        (event, expense) => {
            if (expense) {
                showDialog({
                    title: "Update Expense",
                    content: (
                        <ExpenseForm
                            onSave={handleExpenseAdd}
                            expense={expense}
                        />
                    ),
                });
            } else {
                showDialog({
                    title: "Add New Expense",
                    content: (
                        <ExpenseForm
                            onSave={handleExpenseAdd}
                            staff_id={authData?.authUser?.user?.id}
                        />
                    ),
                });
            }
        },
        [handleExpenseAdd, showDialog]
    );

    return (
        <JumboCardQuick
            title={t("pages.title.expenseList")}
            wrapperSx={{ p: 0 }}
            action={
                <Grid
                    container
                    spacing={4}
                    mb={1}
                    pt={1}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <Grid item sx={{ pt: "42px !important" }}>
                        <Typography variant="h5" fontWeight="300">
                            Total Expenses : {totalCount}
                        </Typography>
                    </Grid>
                    <Grid item sx={{ pt: "36px !important" }}>
                        <Pagination
                            count={totalPages}
                            variant="outlined"
                            shape="rounded"
                            page={currentPage}
                            onChange={onPaginationChange}
                        />
                    </Grid>
                    <Grid item>
                        <Fab
                            size="small"
                            color="primary"
                            aria-label="add"
                            onClick={showAddEditExpenseDialog}
                        >
                            <AddIcon />
                        </Fab>
                    </Grid>
                </Grid>
            }
        >
            <Divider sx={{ mb: 2 }} />
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={onSubmitFilter}
            >
                {({ setFieldValue, values, isSubmitting }) => (
                    <Form noValidate autoComplete="off">
                        <Grid
                            container
                            spacing={4}
                            mb={1}
                            p={1}
                            pl={3}
                            direction="row"
                            // justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            <Grid item xs={3}>
                                <JumboAutoCompleteField
                                    fullWidth
                                    size="small"
                                    name="branch_id"
                                    label="Select Branch"
                                    options={branchList}
                                    optionallabel={"name"}
                                    value={branch}
                                    onChange={(e, value) => {
                                        setFieldValue(
                                            "branch_id",
                                            value !== null
                                                ? value?.id
                                                : initialValues.branch_id
                                        );
                                        setBranch(value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <JumboAutoCompleteField
                                    fullWidth
                                    size="small"
                                    name="category_id"
                                    label="Select Category"
                                    options={expenseCategoryList}
                                    optionallabel={"name"}
                                    value={expenseCategory}
                                    onChange={(e, value) => {
                                        setFieldValue(
                                            "category_id",
                                            value !== null
                                                ? value?.id
                                                : initialValues.category_id
                                        );
                                        setExpenseCategory(value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <JumboAutoCompleteField
                                    fullWidth
                                    size="small"
                                    name="year"
                                    label="Year"
                                    options={[
                                        "2024",
                                        "2023",
                                    ]}
                                    value={year}
                                    onChange={(e, value) => {
                                        setFieldValue(
                                            "year",
                                            value !== null
                                                ? value
                                                : initialValues.year
                                        );
                                        setYear(value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <JumboAutoCompleteField
                                    fullWidth
                                    size="small"
                                    name="month"
                                    label="Month"
                                    optionallabel={"name"}
                                    options={[
                                        {id:"1", name:"January"},
                                        {id:"2", name:"February"},
                                        {id:"3", name:"March"},
                                        {id:"4", name:"April"},
                                        {id:"5", name:"May"},
                                        {id:"6", name:"June"},
                                        {id:"7", name:"July"},
                                        {id:"8", name:"August"},
                                        {id:"9", name:"September"},
                                        {id:"10", name:"October"},
                                        {id:"11", name:"November"},
                                        {id:"12", name:"December"},
                                    ]}
                                    value={month}
                                    onChange={(e, value) => {
                                        setFieldValue(
                                            "month",
                                            value !== null
                                                ? value?.id
                                                : initialValues.month
                                        );
                                        setMonth(value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Filter
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
            <Divider sx={{ mb: 2 }} />

            <JumboScrollbar
                autoHeight={true}
                autoHideTimeout={4000}
                autoHeightMin={660}
                autoHide={true}
                hideTracksWhenNotNeeded
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell sx={{ pl: 3 }}>
                                Date and Time
                            </StyledTableCell>
                            <StyledTableCell>Particulars</StyledTableCell>
                            <StyledTableCell>Category</StyledTableCell>
                            <StyledTableCell>Amount</StyledTableCell>
                            <StyledTableCell>Remarks</StyledTableCell>
                            <StyledTableCell>Added By</StyledTableCell>
                            <StyledTableCell>Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {expenseList?.map((expense) => (
                            <ExpenseListItem
                                item={expense}
                                key={expense.id}
                                handleItemClick={() => {
                                    showAddEditExpenseDialog("", expense);
                                }}
                                handleRemoveExpense={handleRemoveExpense}
                            />
                        ))}
                    </TableBody>
                </Table>
            </JumboScrollbar>
            <Snackbar
                open={alertOpen}
                onClose={handleAlertClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert
                    onClose={handleAlertClose}
                    severity={alertType}
                    sx={{ width: "100%" }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
        </JumboCardQuick>
    );
};

export default ListExpense;
