import React from "react";
import { useField } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const JumboAutoCompleteField = ({ onChange, ...restProps }) => {
    const [field, meta] = useField(restProps);
    const errorText = meta.error && meta.touched ? meta.error : "";
    return (
        <Autocomplete
            onChange={onChange}
            onBlur={field.handleBlur}
            value={restProps?.value}
            readOnly={restProps?.readOnly}
            disablePortal
            id="combo-box-demo"
            options={restProps?.options || []}
            getOptionLabel={(option) =>
                restProps?.optionallabel
                    ? option[restProps?.optionallabel]
                    : option
            }
            noOptionsText={
                <div
                    onClick={() => {
                        console.log("hello");
                    }}
                >
                    Create +
                </div>
            }
            isOptionEqualToValue={(option, value) =>
                option[restProps?.name] === value[restProps?.name]
            }
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        {...restProps}
                        onBlur={field.handleBlur}
                        helperText={errorText}
                        error={!!errorText}
                    />
                );
            }}
        />
    );
};

export default JumboAutoCompleteField;
