import React from "react";
import AddEnquiry from "app/pages/enquiry/AddEnquiry";
import ListEnquiry from "app/pages/enquiry/ListEnquiry";
import Page from "@jumbo/shared/Page";

const enquiryRoutes = [
    {
        path: ["/enquiry/edit/:id", "/enquiry/add"],
        element: <Page component={AddEnquiry} />,
    },
    {
        path: "/enquiry/list",
        element: <Page component={ListEnquiry} />,
    },
];

export default enquiryRoutes;
