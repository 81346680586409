import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { FieldArray, Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Div from "@jumbo/shared/Div";
import { purposeService } from "app/services/purpose-service";
import { Grid, Tooltip, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";

const validationSchema = yup.object({
    name: yup.string("Enter purpose name").required("Name is required"),
    classic_fee: yup
        .string("Enter Classic Fee")
        .required("Classic Fee is required"),
});
const initialValues = {
    name: "",
    classic_fee: "",
    external: [
        {
            fee: "",
            remarks: "",
        },
    ],
};

const PurposeForm = ({ purpose, onSave }) => {
    const [totalFee, setTotalFee] = useState(0);
    const arrayHelpersRef = useRef(null);
    const formikRef = useRef();

    const onPurposeSave = (data, { setSubmitting }) => {
        setSubmitting(true);

        if (purpose?.id) {
            purposeService.update(data).then((result) => {
                if (result?.status) {
                    onSave(purpose?.id, "edit");
                } else {
                    console.log("Purpose updating failed ", result);
                }
                setSubmitting(false);
            });
        } else {
            purposeService.add(data).then((result) => {
                if (result?.status) {
                    onSave(result?.id);
                } else {
                    console.log("Purpose adding failed ", result);
                }
                setSubmitting(false);
            });
        }
    };

    const updateTotalFee = () => {
        // classic fee + total of external fees
        let totalFee =
            formikRef?.current?.values?.classic_fee === ""
                ? 0
                : parseFloat(formikRef?.current?.values?.classic_fee);
        totalFee = formikRef?.current?.values?.external?.reduce(
            (acc, value) => {
                return acc + (value?.fee === "" ? 0 : parseFloat(value?.fee));
            },
            totalFee
        );
        setTotalFee(totalFee);
    };

    useEffect(() => {
        if (purpose?.id) updateTotalFee();
    }, [purpose]);

    return (
        <Formik
            validateOnChange={true}
            validationSchema={validationSchema}
            initialValues={purpose?.id ? purpose : initialValues}
            enableReinitialize={true}
            onSubmit={onPurposeSave}
            innerRef={formikRef}
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form noValidate autoComplete="off">
                    <Div
                        sx={{
                            "& .MuiTextField-root": {
                                mb: 3,
                            },
                        }}
                    >
                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="name"
                            label="Name"
                        />
                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="classic_fee"
                            label="Classic Fee1"
                            onChange={async (e) => {
                                await setFieldValue(
                                    "classic_fee",
                                    e?.target?.value !== null
                                        ? e?.target?.value
                                        : initialValues.classic_fee
                                );
                                updateTotalFee();
                            }}
                        />
                        <Grid container spacing={2} mb={1}>
                            <Grid item>
                                <Typography
                                    variant="h4"
                                    fontWeight="300"
                                    mb={2}
                                >
                                    External Fee Details
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Tooltip title="Add Purpose">
                                    <IconButton
                                        sx={{
                                            marginTop: "-10px!important",
                                        }}
                                        onClick={() => {
                                            arrayHelpersRef.current.push({
                                                fee: "",
                                                remarks: "",
                                            });
                                        }}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <FieldArray
                            name="external"
                            render={(arrayHelpers) => {
                                arrayHelpersRef.current = arrayHelpers;

                                return (
                                    <>
                                        {values.external?.length > 0 &&
                                            values.external?.map(
                                                (ext_fee, index) => (
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        mb={1}
                                                        key={index}
                                                    >
                                                        <Grid item xs={3}>
                                                            <JumboTextField
                                                                fullWidth
                                                                size="small"
                                                                variant="outlined"
                                                                name={`external.${index}.fee`}
                                                                label="Fee"
                                                                onChange={async (
                                                                    e
                                                                ) => {
                                                                    await setFieldValue(
                                                                        `external.${index}.fee`,
                                                                        e
                                                                            ?.target
                                                                            ?.value !==
                                                                            null
                                                                            ? e
                                                                                  ?.target
                                                                                  ?.value
                                                                            : ""
                                                                    );
                                                                    updateTotalFee();
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <JumboTextField
                                                                fullWidth
                                                                size="small"
                                                                variant="outlined"
                                                                name={`external.${index}.remarks`}
                                                                label="Remarks"
                                                            />
                                                        </Grid>
                                                        {values.external
                                                            ?.length > 1 && (
                                                            <Grid item xs={1}>
                                                                <Tooltip title="Remove Purpose">
                                                                    <IconButton
                                                                        onClick={async () => {
                                                                            await arrayHelpers.remove(
                                                                                index
                                                                            );
                                                                            updateTotalFee();
                                                                        }}
                                                                    >
                                                                        <CloseIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                )
                                            )}
                                    </>
                                );
                            }}
                        />
                        <Typography variant="h6" fontWeight="300" mb={2}>
                            Total Fee : {totalFee}
                        </Typography>
                        <LoadingButton
                            fullWidth
                            type="submit"
                            variant="contained"
                            size="large"
                            sx={{ mb: 3 }}
                            loading={isSubmitting}
                        >
                            Save
                        </LoadingButton>
                    </Div>
                </Form>
            )}
        </Formik>
    );
};
PurposeForm.defaultProps = {
    onSave: () => {},
};
export default PurposeForm;
