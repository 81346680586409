import React from "react";
// import AddCustomer from "app/pages/customer/AddCustomer";
import ListCustomer from "app/pages/customer/ListCustomer";
import Page from "@jumbo/shared/Page";

const customerRoutes = [
    // {
    //     path: ["/customer/edit/:id", "/customer/add"],
    //     element: <Page component={AddCustomer} />,
    // },
    {
        path: "/customer/list",
        element: <Page component={ListCustomer} />,
    },
];

export default customerRoutes;
