import jwtAuthAxios from "./auth/jwtAuth";

const purposeService = {};

purposeService.getList = async () => {
    const {data} = await jwtAuthAxios.post("/index.php?action=listPurpose");
    return data;
};
purposeService.add = async (payload) => {
    const {data} = await jwtAuthAxios.post("/index.php?action=addPurpose", payload);
    return data;
};
purposeService.update = async (payload) => {
    const {data} = await jwtAuthAxios.post("/index.php?action=updatePurpose", payload);
    return data;
};
purposeService.remove = async (payload) => {
    const {data} = await jwtAuthAxios.post("/index.php?action=removePurpose", payload);
    return data;
};
export {purposeService};
