import React from "react";
import { TableCell, TableRow } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import styled from "@emotion/styled";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "& .MuiTableCell-root": {
        borderBottom: "none",
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
    },

    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },

    "&:hover": {
        backgroundColor: theme.palette.action.hover,
    },
}));

const CourierListItem = ({ item, handleItemClick, handleRemoveCourier }) => {
    return (
        <StyledTableRow
            key={`${item.id}`}
            onClick={handleItemClick}
            sx={{ cursor: "pointer" }}
        >
            <TableCell sx={{ pl: (theme) => theme.spacing(3) }}>
                {item.customer_name}
            </TableCell>
            <TableCell>{item.customer_mobile}</TableCell>
            <TableCell>{item.to_address}</TableCell>
            <TableCell>{item.to_name}</TableCell>
            <TableCell>{item.tracking_no}</TableCell>
            <TableCell>{item.ups_no}</TableCell>
            <TableCell>{item.amount}</TableCell>
            <TableCell>
                <DeleteOutlineIcon
                    onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveCourier(item?.id);
                    }}
                />
            </TableCell>
        </StyledTableRow>
    );
};
/* Todo item prop define */
export default CourierListItem;
