import jwtAuthAxios from "./auth/jwtAuth";

const staffService = {};

staffService.getList = async () => {
    const {data} = await jwtAuthAxios.post("/index.php?action=listUser");
    return data;
};
staffService.add = async (payload) => {
    const {data} = await jwtAuthAxios.post("/index.php?action=addUser", payload);
    return data;
};
staffService.update = async (payload) => {
    const {data} = await jwtAuthAxios.post("/index.php?action=updateUser", payload);
    return data;
};
staffService.remove = async (payload) => {
    const {data} = await jwtAuthAxios.post("/index.php?action=removeUser", payload);
    return data;
};
export {staffService};
