import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Pagination,
    Typography,
    Divider,
    Button,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import EnquiryListItem from "./EnquiryListItem";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import styled from "@emotion/styled";
import { enquiryService } from "app/services/enquiry-service";
import JumboAutoCompleteField from "@jumbo/components/JumboFormik/JumboAutoCompleteField";
import { Form, Formik } from "formik";
import { customerService } from "app/services/customer-service";
import { purposeService } from "app/services/purpose-service";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: "none",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
}));

const initialValues = {
    customer_id: null,
    purpose_id: null,
    purpose_status: null,
    payment_status: null,
};

const ListEnquiry = () => {
    const { t } = useTranslation();
    const [enquiryList, setEnquiryList] = useState([]);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(0);
    const [totalCount, setTotalCount] = React.useState(0);
    const [customerList, setCustomerList] = useState([]);
    const [purposeList, setPurposeList] = useState([]);
    const [purpose, setPurpose] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [purposeStatus, setPurposeStatus] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState(null);

    const formikRef = useRef();

    const onSubmitFilter = (data, { setSubmitting }) => {
        enquiryService
            .getList({
                limit,
                offset,
                filter: {
                    customer_id: data.customer_id,
                    purpose_id: data.purpose_id,
                    purpose_status: data.purpose_status,
                    payment_status: data.payment_status,
                },
            })
            .then((data) => {
                setSubmitting(false);
                setEnquiryList(data?.enquiry);
                setTotalCount(data?.totalCount);

                //set total pages count
                const quotient = Math.floor(data?.totalCount / data?.limit);
                const reminder = data?.totalCount % data?.limit;
                setTotalPages(quotient + (reminder === 0 ? 0 : 1));

                //set current page
                setCurrentPage(1);
            });
    };

    const onPaginationChange = (event, value) => {
        enquiryService
            .getList({
                limit,
                offset: limit * (value - 1),
                filter: {
                    customer_id: customer?.id || null,
                    purpose_id: purpose?.id || null,
                    purpose_status: purposeStatus || null,
                    payment_status: paymentStatus || null,
                },
            })
            .then((data) => {
                setEnquiryList(data?.enquiry);
                setTotalCount(data?.totalCount);
                setOffset(data?.offset);
                setLimit(data?.limit);

                //set total pages count
                const quotient = Math.floor(data?.totalCount / data?.limit);
                const reminder = data?.totalCount % data?.limit;
                setTotalPages(quotient + (reminder === 0 ? 0 : 1));

                //set current page
                setCurrentPage(value);
            });
    };

    useEffect(() => {
        enquiryService.getList({ limit, offset }).then((data) => {
            setEnquiryList(data?.enquiry);
            setTotalCount(data?.totalCount);
            setOffset(data?.offset);
            setLimit(data?.limit);

            //get total pages count
            const quotient = Math.floor(data?.totalCount / data?.limit);
            const reminder = data?.totalCount % data?.limit;
            setTotalPages(quotient + (reminder === 0 ? 0 : 1));

            //get current page
            setCurrentPage(1);

            //fetch customer list
            customerService.getList().then((customerListData) => {
                setCustomerList(customerListData?.customers);

                //fetch purpose list
                purposeService.getList().then((data) => {
                    setPurposeList(data?.purposes);
                });
            });
        });
    }, []);

    return (
        <JumboCardQuick
            title={t("pages.title.enquiryList")}
            wrapperSx={{ p: 0 }}
            action={
                <Grid
                    container
                    spacing={4}
                    mb={1}
                    pt={1}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <Grid item sx={{ pt: "42px !important" }}>
                        <Typography variant="h5" fontWeight="300">
                            Total Enqueries : {totalCount}
                        </Typography>
                    </Grid>
                    <Grid item sx={{ pt: "36px !important" }}>
                        <Pagination
                            count={totalPages}
                            variant="outlined"
                            shape="rounded"
                            page={currentPage}
                            onChange={onPaginationChange}
                        />
                    </Grid>
                </Grid>
            }
        >
            <Divider sx={{ mb: 2 }} />
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={onSubmitFilter}
                innerRef={formikRef}
            >
                {({ setFieldValue, values, isSubmitting }) => (
                    <Form noValidate autoComplete="off">
                        <Grid
                            container
                            spacing={4}
                            mb={1}
                            p={1}
                            pl={3}
                            direction="row"
                            // justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            <Grid item xs={3}>
                                <JumboAutoCompleteField
                                    fullWidth
                                    size="small"
                                    name="customer_id"
                                    label="Select Customer"
                                    options={customerList}
                                    optionallabel={"name"}
                                    value={customer}
                                    onChange={(e, value) => {
                                        setFieldValue(
                                            "customer_id",
                                            value !== null
                                                ? value?.id
                                                : initialValues.customer_id
                                        );
                                        setCustomer(value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <JumboAutoCompleteField
                                    fullWidth
                                    size="small"
                                    name="purpose_id"
                                    label="Select Purpose"
                                    options={purposeList}
                                    optionallabel={"name"}
                                    value={purpose}
                                    onChange={(e, value) => {
                                        setFieldValue(
                                            "purpose_id",
                                            value !== null
                                                ? value?.id
                                                : initialValues.purpose_id
                                        );
                                        setPurpose(value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <JumboAutoCompleteField
                                    fullWidth
                                    size="small"
                                    name="payment_status"
                                    label="Payment Status"
                                    options={[
                                        "Paid",
                                        "Partially Paid",
                                        "Not Paid",
                                    ]}
                                    value={paymentStatus}
                                    onChange={(e, value) => {
                                        setFieldValue(
                                            "payment_status",
                                            value !== null
                                                ? value
                                                : initialValues.payment_status
                                        );
                                        setPaymentStatus(value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <JumboAutoCompleteField
                                    fullWidth
                                    size="small"
                                    name="purpose_status"
                                    label="Purpose Status"
                                    options={[
                                        "Processing",
                                        "Completed",
                                        "Cancelled",
                                        "Scheduled to Complete After x days",
                                    ]}
                                    value={purposeStatus}
                                    onChange={(e, value) => {
                                        setFieldValue(
                                            "purpose_status",
                                            value !== null
                                                ? value
                                                : initialValues.purpose_status
                                        );
                                        setPurposeStatus(value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Filter
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
            <Divider sx={{ mb: 2 }} />
            <JumboScrollbar
                autoHeight={true}
                autoHideTimeout={4000}
                autoHeightMin={570}
                autoHide={true}
                hideTracksWhenNotNeeded
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell sx={{ pl: 3 }}>
                                Enquiry No
                            </StyledTableCell>
                            <StyledTableCell>Date and Time</StyledTableCell>
                            <StyledTableCell>Customer</StyledTableCell>
                            <StyledTableCell sx={{ pr: 3 }}>
                                Payment Status
                            </StyledTableCell>
                            <StyledTableCell>Purpose Details</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {enquiryList?.map((enquiry, index) => (
                            <EnquiryListItem item={enquiry} key={enquiry.id} />
                        ))}
                    </TableBody>
                </Table>
            </JumboScrollbar>
        </JumboCardQuick>
    );
};

export default ListEnquiry;
