import React from "react";
import * as yup from "yup";
// import { contactService } from "../../../../../services/contact-services";
// import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Div from "@jumbo/shared/Div";
import { customerService } from "app/services/customer-service";

const validationSchema = yup.object({
    name: yup.string("Enter customer name").required("Name is required"),
    email: yup
        .string("Enter customer email")
        .email("Enter a valid email")
        .required("Email is required"),
    mobile: yup.string("Enter customer mobile").required("Mobile is required"),
});
const initialValues = {
    name: "",
    mobile: "",
    whatsapp: "",
    email: "",
    remarks: "",
};

const CustomerForm = ({ customer, onSave }) => {
    const onCustomerSave = (data, { setSubmitting }) => {
        setSubmitting(true);

        if (customer?.id) {
            customerService.update(data).then((result) => {
                if (result?.status) {
                    onSave(customer?.id, 'edit');
                } else {
                    console.log("Customer updating failed ", result);
                }
                setSubmitting(false);
            });
        } else {
            customerService.add(data).then((result) => {
                if (result?.status) {
                    onSave(result?.id);
                } else {
                    console.log("Customer adding failed ", result);
                }
                setSubmitting(false);
            });
        }
    };

    return (
        <Formik
            validateOnChange={true}
            validationSchema={validationSchema}
            initialValues={customer?.id ? customer : initialValues}
            enableReinitialize={true}
            onSubmit={onCustomerSave}
        >
            {({ isSubmitting, setFieldValue }) => (
                <Form noValidate autoComplete="off">
                    <Div
                        sx={{
                            "& .MuiTextField-root": {
                                mb: 3,
                            },
                        }}
                    >
                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="name"
                            label="Name"
                        />
                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="mobile"
                            label="Mobile"
                        />
                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="whatsapp"
                            label="WhatsApp"
                        />
                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="email"
                            label="Email"
                        />
                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="remarks"
                            label="Remarks"
                        />
                        <LoadingButton
                            fullWidth
                            type="submit"
                            variant="contained"
                            size="large"
                            sx={{ mb: 3 }}
                            loading={isSubmitting}
                        >
                            Save
                        </LoadingButton>
                    </Div>
                </Form>
            )}
        </Formik>
    );
};
CustomerForm.defaultProps = {
    onSave: () => {},
};
export default CustomerForm;
