import React from "react";
import ListPurpose from "app/pages/purpose/ListPurpose";
import Page from "@jumbo/shared/Page";

const purposeRoutes = [
    {
        path: "/purpose/list",
        element: <Page component={ListPurpose} />,
    },
];

export default purposeRoutes;
