import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import JumboAutoCompleteField from "@jumbo/components/JumboFormik/JumboAutoCompleteField";
import Div from "@jumbo/shared/Div";
import { courierService } from "app/services/courier-service";
import { branchService } from "app/services/branch-service";
import { formatPayload } from "app/utils/courierUtils";
import { customerService } from "app/services/customer-service";

const validationSchema = yup.object({
    // branch_id: yup.string("Select branch").required("Branch is required"),
    // category_id: yup.string("Select category").required("Category is required"),
    to_address: yup
        .string("Enter to address")
        .required("To address is required"),
    to_name: yup.string("Enter to name").required("To name is required"),
    tracking_no: yup
        .string("Enter tracking number")
        .required("Tracking number is required"),
    ups_no: yup.string("Enter ups number").required("UPS number is required"),
    amount: yup.string("Enter amount").required("Amount is required"),
});
const initialValues = {
    customer_id: null,
    branch_id: null,
    added_by: "",
    to_address: "",
    to_name: "",
    tracking_no: "",
    ups_no: "",
    amount: "",
};

const CourierForm = ({ courier, onSave, staff_id }) => {
    const [branchList, setBranchList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const formikRef = useRef();

    const onCourierSave = (data, { setSubmitting }) => {
        setSubmitting(true);

        if (courier?.id) {
            courierService.update(formatPayload(data)).then((result) => {
                if (result?.status) {
                    onSave(courier?.id, "edit");
                } else {
                    console.log("Courier updating failed ", result);
                }
                setSubmitting(false);
            });
        } else {
            courierService.add(formatPayload(data, staff_id)).then((result) => {
                if (result?.status) {
                    onSave(result?.id);
                } else {
                    console.log("Courier adding failed ", result);
                }
                setSubmitting(false);
            });
        }
    };

    useEffect(() => {
        // if is in edit mode?
        if (courier?.id) {
            // once customerList loaded
            if (customerList?.length) {
                if (formikRef.current) {
                    //update branch_id field with object
                    formikRef.current.setFieldValue(
                        "branch_id",
                        branchList?.find(
                            (item) => item?.id === courier?.branch_id
                        )
                    );
                    //update designation_id field with object
                    formikRef.current.setFieldValue(
                        "customer_id",
                        customerList?.find(
                            (item) => item?.id === courier?.category_id
                        )
                    );
                }
            }
        }
    }, [branchList, customerList, courier]);

    useEffect(() => {
        branchService.getList().then((data) => {
            setBranchList(data?.branches);
            customerService.getList().then((data) => {
                setCustomerList(data?.customers);
            });
        });
    }, []);

    return (
        <Formik
            validateOnChange={true}
            validationSchema={validationSchema}
            initialValues={courier?.id ? courier : initialValues}
            enableReinitialize={true}
            onSubmit={onCourierSave}
            innerRef={formikRef}
        >
            {({ isSubmitting, setFieldValue, values }) => (
                <Form noValidate autoComplete="off">
                    <Div
                        sx={{
                            "& .MuiTextField-root": {
                                mb: 3,
                            },
                        }}
                    >
                        {!courier?.id && (
                            <>
                                <JumboAutoCompleteField
                                    fullWidth
                                    size="small"
                                    name="branch_id"
                                    label="Branch"
                                    options={branchList}
                                    optionallabel={"name"}
                                    value={values?.branch_id || null}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setFieldValue("branch_id", value);
                                        }
                                    }}
                                />
                                <JumboAutoCompleteField
                                    fullWidth
                                    size="small"
                                    name="customer_id"
                                    label="Customer"
                                    options={customerList}
                                    optionallabel={"name"}
                                    value={values?.customer_id || null}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setFieldValue("customer_id", value);
                                        }
                                    }}
                                />
                            </>
                        )}

                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="to_address"
                            label="To Address"
                        />
                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="to_name"
                            label="To Name"
                        />
                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="tracking_no"
                            label="Tracking No."
                        />
                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="ups_no"
                            label="UPS No."
                        />
                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="amount"
                            label="Amount"
                        />
                        <LoadingButton
                            fullWidth
                            type="submit"
                            variant="contained"
                            size="large"
                            sx={{ mb: 3 }}
                            loading={isSubmitting}
                        >
                            Save
                        </LoadingButton>
                    </Div>
                </Form>
            )}
        </Formik>
    );
};
CourierForm.defaultProps = {
    onSave: () => {},
};
export default CourierForm;
