const formatEnquiryPayload = (data) => {
    const formatedPurposeDetails = data?.purpose_details?.map((purpose) => {
        const newFormat = {
            ...purpose,
            purpose_id: purpose?.purpose?.id,
            scheduled_to_complete_on: addDays(
                purpose?.scheduledToCompleteAfter
            ),
        };
        delete newFormat.purpose;
        delete newFormat.scheduledToCompleteAfter;
        return newFormat;
    });
    return { ...data, purpose_details: formatedPurposeDetails };
};

const addDays = (days) => {
    const date = new Date();
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result.toISOString().split("T")[0];
};

const calculateTotalPayment = (purposeList) => {
    let totalFeeToPay = 0;
    totalFeeToPay = purposeList?.reduce(
        (acc, currentValue) =>
            currentValue?.purpose
                ? currentValue?.purpose.classic_fee + acc
                : acc,
        0
    );
    return totalFeeToPay;
};

// a and b are javascript Date objects
const dateDiffInDays = (b) => {
    const a = new Date();
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

export { formatEnquiryPayload, calculateTotalPayment, dateDiffInDays };
