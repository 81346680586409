import React from "react";
import ListExpense from "app/pages/expense/ListExpense";
import Page from "@jumbo/shared/Page";

const expenseRoutes = [
    {
        path: "/expense/list",
        element: <Page component={ListExpense} />,
    },
];

export default expenseRoutes;
