import React from "react";
import * as yup from "yup";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Div from "@jumbo/shared/Div";
import { branchService } from "app/services/branch-service";

const validationSchema = yup.object({
    name: yup.string("Enter branch name").required("Name is required"),
    address: yup.string("Enter branch address").required("Address is required"),
    mobile1: yup.string("Enter mobile 1").required("Mobile 1 is required"),
});
const initialValues = {
    name: "",
    address: "",
    mobile1: "",
    mobile2: "",
};

const BranchForm = ({ branch, onSave }) => {
    const onBranchSave = (data, { setSubmitting }) => {
        setSubmitting(true);

        if (branch?.id) {
            branchService.update(data).then((result) => {
                if (result?.status) {
                    onSave(branch?.id, 'edit');
                } else {
                    console.log("Branch updating failed ", result);
                }
                setSubmitting(false);
            });
        } else {
            branchService.add(data).then((result) => {
                if (result?.status) {
                    onSave(result?.id);
                } else {
                    console.log("Branch adding failed ", result);
                }
                setSubmitting(false);
            });
        }
    };

    return (
        <Formik
            validateOnChange={true}
            validationSchema={validationSchema}
            initialValues={branch?.id ? branch : initialValues}
            enableReinitialize={true}
            onSubmit={onBranchSave}
        >
            {({ isSubmitting }) => (
                <Form noValidate autoComplete="off">
                    <Div
                        sx={{
                            "& .MuiTextField-root": {
                                mb: 3,
                            },
                        }}
                    >
                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="name"
                            label="Name"
                        />
                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="address"
                            label="Address"
                        />
                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="mobile1"
                            label="Mobile 1"
                        />
                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="mobile2"
                            label="Mobile 2"
                        />
                        <LoadingButton
                            fullWidth
                            type="submit"
                            variant="contained"
                            size="large"
                            sx={{ mb: 3 }}
                            loading={isSubmitting}
                        >
                            Save
                        </LoadingButton>
                    </Div>
                </Form>
            )}
        </Formik>
    );
};
BranchForm.defaultProps = {
    onSave: () => {},
};
export default BranchForm;
