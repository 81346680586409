import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import JumboAutoCompleteField from "@jumbo/components/JumboFormik/JumboAutoCompleteField";
import Div from "@jumbo/shared/Div";
import { expenseService } from "app/services/expense-service";
import { branchService } from "app/services/branch-service";
import { expenseCategoryService } from "app/services/expenseCategory-service";
import { formatPayload } from "app/utils/expenseUtils";

const validationSchema = yup.object({
    // branch_id: yup.string("Select branch").required("Branch is required"),
    // category_id: yup.string("Select category").required("Category is required"),
    particulars: yup
        .string("Select particulars")
        .required("Particulars is required"),
    amount: yup.string("Enter amount").required("Amount is required"),
    remarks: yup.string("Enter remarks").required("Remarks is required"),
});
const initialValues = {
    category_id: null,
    branch_id: null,
    particulars: "",
    amount: "",
    added_by: "",
    remarks: "",
};

const ExpenseForm = ({ expense, onSave, staff_id }) => {
    const [branchList, setBranchList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const formikRef = useRef();

    const onExpenseSave = (data, { setSubmitting }) => {
        setSubmitting(true);

        if (expense?.id) {
            expenseService.update(formatPayload(data)).then((result) => {
                if (result?.status) {
                    onSave(expense?.id, "edit");
                } else {
                    console.log("Expense updating failed ", result);
                }
                setSubmitting(false);
            });
        } else {
            expenseService.add(formatPayload(data, staff_id)).then((result) => {
                if (result?.status) {
                    onSave(result?.id);
                } else {
                    console.log("Expense adding failed ", result);
                }
                setSubmitting(false);
            });
        }
    };

    useEffect(() => {
        // if is in edit mode?
        if (expense?.id) {
            // once categoryList loaded
            if (categoryList?.length) {
                if (formikRef.current) {
                    //update branch_id field with object
                    formikRef.current.setFieldValue(
                        "branch_id",
                        branchList?.find(
                            (item) => item?.id === expense?.branch_id
                        )
                    );
                    //update designation_id field with object
                    formikRef.current.setFieldValue(
                        "category_id",
                        categoryList?.find(
                            (item) => item?.id === expense?.category_id
                        )
                    );
                }
            }
        }
    }, [branchList, categoryList, expense]);

    useEffect(() => {
        branchService.getList().then((data) => {
            setBranchList(data?.branches);
            expenseCategoryService.getList().then((data) => {
                setCategoryList(data?.expenseCategories);
            });
        });
    }, []);

    return (
        <Formik
            validateOnChange={true}
            validationSchema={validationSchema}
            initialValues={expense?.id ? expense : initialValues}
            enableReinitialize={true}
            onSubmit={onExpenseSave}
            innerRef={formikRef}
        >
            {({ isSubmitting, setFieldValue, values }) => (
                <Form noValidate autoComplete="off">
                    <Div
                        sx={{
                            "& .MuiTextField-root": {
                                mb: 3,
                            },
                        }}
                    >
                        {!expense?.id && (
                            <>
                                <JumboAutoCompleteField
                                    fullWidth
                                    size="small"
                                    name="branch_id"
                                    label="Branch"
                                    options={branchList}
                                    optionallabel={"name"}
                                    value={values?.branch_id || null}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setFieldValue("branch_id", value);
                                        }
                                    }}
                                />
                                <JumboAutoCompleteField
                                    fullWidth
                                    size="small"
                                    name="category_id"
                                    label="Category"
                                    options={categoryList}
                                    optionallabel={"name"}
                                    value={values?.category_id || null}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setFieldValue("category_id", value);
                                        }
                                    }}
                                />
                            </>
                        )}

                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="particulars"
                            label="Particulars"
                        />
                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="amount"
                            label="Amount"
                        />
                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="remarks"
                            label="Remarks"
                        />
                        <LoadingButton
                            fullWidth
                            type="submit"
                            variant="contained"
                            size="large"
                            sx={{ mb: 3 }}
                            loading={isSubmitting}
                        >
                            Save
                        </LoadingButton>
                    </Div>
                </Form>
            )}
        </Formik>
    );
};
ExpenseForm.defaultProps = {
    onSave: () => {},
};
export default ExpenseForm;
