import React from "react";
import ListBranch from "app/pages/branch/ListBranch";
import Page from "@jumbo/shared/Page";

const branchRoutes = [
    {
        path: "/branch/list",
        element: <Page component={ListBranch} />,
    },
];

export default branchRoutes;
