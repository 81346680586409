import React from "react";
import { TableCell, TableRow } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import styled from "@emotion/styled";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "& .MuiTableCell-root": {
        borderBottom: "none",
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
    },

    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },

    "&:hover": {
        backgroundColor: theme.palette.action.hover,
    },
}));

const PurposeListItem = ({ item, handleItemClick, handleRemovePurpose }) => {
    return (
        <StyledTableRow
            key={`${item.name}`}
            onClick={handleItemClick}
            sx={{ cursor: "pointer" }}
        >
            <TableCell sx={{ pl: (theme) => theme.spacing(3) }}>
                {item.name}
            </TableCell>
            <TableCell>{item.classic_fee}</TableCell>
            <TableCell>
                {item.external?.reduce((acc, curr_val) => {
                    return curr_val?.fee ? acc + curr_val?.fee : 0;
                }, 0)}
            </TableCell>
            <TableCell align="right">
                <DeleteOutlineIcon
                    onClick={(e) => {
                        e.stopPropagation();
                        handleRemovePurpose(item?.id);
                    }}
                />
            </TableCell>
        </StyledTableRow>
    );
};
/* Todo item prop define */
export default PurposeListItem;
