import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ExpenseCategoryListItem from "./ExpenseCategoryListItem";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import styled from "@emotion/styled";
import { expenseCategoryService } from "app/services/expenseCategory-service";
import ExpenseCategoryForm from "./ExpenseCategoryForm";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: "none",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant="filled"
            {...props}
            sx={{ zIndex: 20000 }}
        />
    );
});

const ListExpenseCategory = () => {
    const { t } = useTranslation();
    const [expenseCategoryList, setExpenseCategoryList] = useState([]);
    const { hideDialog, showDialog } = useJumboDialog();
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");

    useEffect(() => {
        expenseCategoryService.getList().then((data) => {
            setExpenseCategoryList(data?.expenseCategories);
        });
    }, []);

    const hideDialogAndRefreshExpenseCategoryList = React.useCallback(() => {
        //fetch updated expenseCategory list
        expenseCategoryService.getList().then((data) => {
            setExpenseCategoryList(data?.expenseCategories);
            setAlertType("success");
            setAlertMessage("Expense Category Removed Successfully !");
            setAlertOpen(true);
            hideDialog();
        });
    }, [hideDialog]);

    const handleRemoveExpenseCategory = (expenseCategoryId) => {
        showDialog({
            variant: "confirm",
            title: "Are you sure about deleting this expense category?",
            content: "You won't be able to recover this expense category later",
            onYes: () =>
                expenseCategoryService.remove({ id: expenseCategoryId }).then(() => {
                    hideDialogAndRefreshExpenseCategoryList();
                }),
            onNo: hideDialog,
        });
    };

    const handleAlertClose = (event, reason) => {
        setAlertOpen(false);
    };

    const handleExpenseCategoryAdd = React.useCallback(
        (expenseCategoryId, mode) => {
            if (mode === "edit") {
                setAlertType("success");
                setAlertMessage("Expense Category Udpated Successfully !");
            } else {
                setAlertType("success");
                setAlertMessage("Expense Category Added Successfully !");
            }
            setAlertOpen(true);

            //fetch updated expenseCategory list
            expenseCategoryService.getList().then((data) => {
                setExpenseCategoryList(data?.expenseCategories);
                hideDialog();
            });
        },
        [hideDialog]
    );

    const showAddEditExpenseCategoryDialog = React.useCallback(
        (event, expenseCategory) => {
            if (expenseCategory) {
                showDialog({
                    title: "Update Expense Category",
                    content: (
                        <ExpenseCategoryForm
                            onSave={handleExpenseCategoryAdd}
                            expenseCategory={expenseCategory}
                        />
                    ),
                });
            } else {
                showDialog({
                    title: "Add New Expense Category",
                    content: <ExpenseCategoryForm onSave={handleExpenseCategoryAdd} />,
                });
            }
        },
        [handleExpenseCategoryAdd, showDialog]
    );

    return (
        <JumboCardQuick
            title={t("pages.title.expenseCategoryList")}
            wrapperSx={{ p: 0 }}
            action={
                <Fab
                    size="small"
                    color="primary"
                    aria-label="add"
                    onClick={showAddEditExpenseCategoryDialog}
                >
                    <AddIcon />
                </Fab>
            }
        >
            <JumboScrollbar
                autoHeight={true}
                autoHideTimeout={4000}
                autoHeightMin={690}
                autoHide={true}
                hideTracksWhenNotNeeded
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell sx={{ pl: 3 }}>
                                Name
                            </StyledTableCell>
                            <StyledTableCell align="right">Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {expenseCategoryList?.map((expenseCategory) => (
                            <ExpenseCategoryListItem
                                item={expenseCategory}
                                key={expenseCategory.id}
                                handleItemClick={() => {
                                    showAddEditExpenseCategoryDialog(
                                        "",
                                        expenseCategory
                                    );
                                }}
                                handleRemoveExpenseCategory={
                                    handleRemoveExpenseCategory
                                }
                            />
                        ))}
                    </TableBody>
                </Table>
            </JumboScrollbar>
            <Snackbar
                open={alertOpen}
                onClose={handleAlertClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert
                    onClose={handleAlertClose}
                    severity={alertType}
                    sx={{ width: "100%" }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
        </JumboCardQuick>
    );
};

export default ListExpenseCategory;
