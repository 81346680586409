import jwtAuthAxios from "./auth/jwtAuth";

const expenseService = {};

expenseService.getList = async (payload) => {
    const { data } = await jwtAuthAxios.post(
        "/index.php?action=listExpense",
        payload
    );
    return data;
};
expenseService.add = async (payload) => {
    const { data } = await jwtAuthAxios.post(
        "/index.php?action=addExpense",
        payload
    );
    return data;
};
expenseService.update = async (payload) => {
    const { data } = await jwtAuthAxios.post(
        "/index.php?action=updateExpense",
        payload
    );
    return data;
};
expenseService.remove = async (payload) => {
    const { data } = await jwtAuthAxios.post(
        "/index.php?action=removeExpense",
        payload
    );
    return data;
};

export { expenseService };
