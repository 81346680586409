import React from "react";
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import AddTaskIcon from '@mui/icons-material/AddTask';
import PeopleIcon from '@mui/icons-material/People';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import MoneyIcon from '@mui/icons-material/Money';
import TodayIcon from '@mui/icons-material/Today';
import GradingIcon from '@mui/icons-material/Grading';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import WorkIcon from '@mui/icons-material/Work';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CategoryIcon from '@mui/icons-material/Category';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import EventNoteIcon from '@mui/icons-material/EventNote';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import LockResetIcon from '@mui/icons-material/LockReset';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import ScreenLockRotationIcon from '@mui/icons-material/ScreenLockRotation';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RunningWithErrorsOutlinedIcon from '@mui/icons-material/RunningWithErrorsOutlined';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import StreamOutlinedIcon from '@mui/icons-material/StreamOutlined';
import ListIcon from '@mui/icons-material/List';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import PieChartOutlineOutlinedIcon from '@mui/icons-material/PieChartOutlineOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import CallEndIcon from '@mui/icons-material/CallEnd';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import GridViewIcon from '@mui/icons-material/GridView';
import Grid3x3OutlinedIcon from '@mui/icons-material/Grid3x3Outlined';
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined';

const menus = [
    {
        label: 'sidebar.menu.home',
        type: "section",
        children: [
            {
                uri: "/dashboards",
                label: 'sidebar.menu.dashboard',
                type: "nav-item",
                icon: <GraphicEqIcon sx={{fontSize: 20}}/>
            },
            {
                label: 'sidebar.menu.enquiry',
                type: "collapsible",
                icon: <AddTaskIcon sx={{fontSize: 20}}/>,
                children: [
                    {
                        uri: "/enquiry/add",
                        label: 'sidebar.menuItem.add',
                        type: "nav-item"
                    },
                    {
                        uri: "/enquiry/list",
                        label: 'sidebar.menuItem.list',
                        type: "nav-item"
                    }
                ]
            },
            {
                uri: "/customer/list",
                label: 'sidebar.menu.customer',
                type: "nav-item",
                icon: <PeopleIcon sx={{fontSize: 20}}/>,
            },
            {
                uri: "/expense/list",
                label: 'sidebar.menu.expense',
                type: "nav-item",
                icon: <AddShoppingCartIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/courier/list",
                label: 'sidebar.menu.courier',
                type: "nav-item",
                icon: <LocalShippingIcon sx={{fontSize: 20}}/>,
            },
            {
                label: 'sidebar.menu.salaryBook',
                type: "collapsible",
                icon: <MoneyIcon sx={{fontSize: 20}}/>,
                children: [
                    {
                        uri: "/salaryBook/add",
                        label: 'sidebar.menuItem.add',
                        type: "nav-item"
                    },
                    {
                        uri: "/salaryBook/list",
                        label: 'sidebar.menuItem.list',
                        type: "nav-item"
                    }
                ]
            },
            {
                label: 'sidebar.menu.dayBook',
                type: "collapsible",
                icon: <TodayIcon sx={{fontSize: 20}}/>,
                children: [
                    {
                        uri: "/dayBook/add",
                        label: 'sidebar.menuItem.add',
                        type: "nav-item"
                    },
                    {
                        uri: "/dayBook/list",
                        label: 'sidebar.menuItem.list',
                        type: "nav-item"
                    }
                ]
            },
            {
                uri: "/purpose/list",
                label: 'sidebar.menu.purpose',
                type: "nav-item",
                icon: <GradingIcon sx={{fontSize: 20}}/>,
            },            
            {
                uri: "/expenseCategory/list",
                label: 'sidebar.menu.expenseCategory',
                type: "nav-item",
                icon: <CategoryIcon sx={{fontSize: 20}}/>,
            },
            {
                uri: "/staff/list",
                label: 'sidebar.menu.staff',
                type: "nav-item",
                icon: <AssignmentIndIcon sx={{fontSize: 20}}/>,
            },
            {
                uri: "/designation/list",
                label: 'sidebar.menu.designation',
                type: "nav-item",
                icon: <WorkIcon sx={{fontSize: 20}}/>,
            },
            {
                uri: "/branch/list",
                label: 'sidebar.menu.branches',
                type: "nav-item",
                icon: <AccountTreeIcon sx={{fontSize: 20}}/>,
            },
            
        ]
    },
];

export default menus;
