import jwtAuthAxios from "./auth/jwtAuth";

const customerService = {};

customerService.getList = async (payload) => {
    const { data } = await jwtAuthAxios.post(
        "/index.php?action=listCustomer",
        payload
    );
    return data;
};
customerService.add = async (payload) => {
    const { data } = await jwtAuthAxios.post(
        "/index.php?action=addCustomer",
        payload
    );
    return data;
};
customerService.update = async (payload) => {
    const { data } = await jwtAuthAxios.post(
        "/index.php?action=updateCustomer",
        payload
    );
    return data;
};

customerService.getById = async (id) => {
    //@todo pass id to api
    const { data } = await jwtAuthAxios.post("/index.php?action=viewCustomer");
    return data;
};
export { customerService };
