import React from "react";
import { TableCell, TableRow } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import styled from "@emotion/styled";
import { format } from "date-fns";
import { RadioButtonUnchecked } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "& .MuiTableCell-root": {
        borderBottom: "none",
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
    },

    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },

    "&:hover": {
        backgroundColor: theme.palette.action.hover,
    },
}));

const EnquiryListItem = ({ item }) => {
    const navigate = useNavigate();
    const handleItemClick = () => {
        navigate(`/enquiry/edit/${item.id}`);
    };
    return (
        <StyledTableRow
            key={item.name}
            onClick={handleItemClick}
            sx={{ cursor: "pointer" }}
        >
            <TableCell sx={{ pl: (theme) => theme.spacing(3) }}>
                {item.id}
            </TableCell>
            <TableCell>
                {format(new Date(item.timestamp), "dd/MM/yyyy hh:mm aa")}
            </TableCell>
            <TableCell>{item.customer_name}</TableCell>
            <TableCell>{item.payment_status}</TableCell>
            <TableCell>
                <List sx={{ width: "100%", maxWidth: 360 }}>
                    {item.enquiryPurposeDetails?.map((purpose, index) => {
                        let color =
                            purpose.status == "processing"
                                ? "success"
                                : purpose.status == "completed"
                                ? "primary"
                                : "secondary";
                        return (
                            <ListItem
                                key={purpose?.id}
                                disableGutters
                                secondaryAction={
                                    <Icon edge="end">
                                        <RadioButtonUnchecked
                                            color={color}
                                            fontSize="small"
                                        />
                                    </Icon>
                                }
                                disablePadding
                            >
                                <ListItemText
                                    id={index}
                                    primary={purpose?.purpose_name}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </TableCell>
        </StyledTableRow>
    );
};
/* Todo item prop define */
export default EnquiryListItem;
