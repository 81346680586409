import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Pagination,
    Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CourierListItem from "./CourierListItem";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import styled from "@emotion/styled";
import { courierService } from "app/services/courier-service";
import CourierForm from "./CourierForm";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: "none",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant="filled"
            {...props}
            sx={{ zIndex: 20000 }}
        />
    );
});

const ListCourier = () => {
    const { t } = useTranslation();
    const [courierList, setCourierList] = useState([]);
    const { hideDialog, showDialog } = useJumboDialog();
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [limit, setLimit] = useState(13);
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(0);
    const [totalCount, setTotalCount] = React.useState(0);

    const authData = useJumboAuth();

    const onPaginationChange = (event, value) => {
        courierService
            .getList({ limit, offset: limit * (value - 1) })
            .then((data) => {
                setCourierList(data?.couriers);
                setTotalCount(data?.totalCount);
                setOffset(data?.offset);
                setLimit(data?.limit);

                //set total pages count
                const quotient = Math.floor(data?.totalCount / data?.limit);
                const reminder = data?.totalCount % data?.limit;
                setTotalPages(quotient + (reminder === 0 ? 0 : 1));

                //set current page
                setCurrentPage(value);
            });
    };

    useEffect(() => {
        courierService.getList({ limit, offset }).then((data) => {
            setCourierList(data?.couriers);
            setTotalCount(data?.totalCount);
            setOffset(data?.offset);
            setLimit(data?.limit);

            //get total pages count
            const quotient = Math.floor(data?.totalCount / data?.limit);
            const reminder = data?.totalCount % data?.limit;
            setTotalPages(quotient + (reminder === 0 ? 0 : 1));

            //get current page
            setCurrentPage(1);
        });
    }, []);

    const hideDialogAndRefreshCourierList = React.useCallback(() => {
        //fetch updated courier list
        courierService.getList().then((data) => {
            setCourierList(data?.couriers);
            setAlertType("success");
            setAlertMessage("Courier Removed Successfully !");
            setAlertOpen(true);
            hideDialog();
        });
    }, [hideDialog]);

    const handleRemoveCourier = (courierId) => {
        showDialog({
            variant: "confirm",
            title: "Are you sure about deleting this courier?",
            content: "You won't be able to recover this courier later",
            onYes: () =>
                courierService.remove({ id: courierId }).then(() => {
                    hideDialogAndRefreshCourierList();
                }),
            onNo: hideDialog,
        });
    };

    const handleAlertClose = (event, reason) => {
        setAlertOpen(false);
    };

    const handleCourierAdd = React.useCallback(
        (courierId, mode) => {
            if (mode === "edit") {
                setAlertType("success");
                setAlertMessage("Courier Udpated Successfully !");
            } else {
                setAlertType("success");
                setAlertMessage("Courier Added Successfully !");
            }
            setAlertOpen(true);

            //fetch updated courier list
            courierService.getList().then((data) => {
                setCourierList(data?.couriers);
                hideDialog();
            });
        },
        [hideDialog]
    );

    const showAddEditCourierDialog = React.useCallback(
        (event, courier) => {
            if (courier) {
                showDialog({
                    title: "Update Courier",
                    content: (
                        <CourierForm
                            onSave={handleCourierAdd}
                            courier={courier}
                        />
                    ),
                });
            } else {
                showDialog({
                    title: "Add New Courier",
                    content: (
                        <CourierForm
                            onSave={handleCourierAdd}
                            staff_id={authData?.authUser?.user?.id}
                        />
                    ),
                });
            }
        },
        [handleCourierAdd, showDialog]
    );

    return (
        <JumboCardQuick
            title={t("pages.title.courierList")}
            wrapperSx={{ p: 0 }}
            action={
                <Grid
                    container
                    spacing={4}
                    mb={1}
                    pt={1}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <Grid item sx={{ pt: "42px !important" }}>
                        <Typography variant="h5" fontWeight="300">
                            Total Couriers : {totalCount}
                        </Typography>
                    </Grid>
                    <Grid item sx={{ pt: "36px !important" }}>
                        <Pagination
                            count={totalPages}
                            variant="outlined"
                            shape="rounded"
                            page={currentPage}
                            onChange={onPaginationChange}
                        />
                    </Grid>
                    <Grid item>
                        <Fab
                            size="small"
                            color="primary"
                            aria-label="add"
                            onClick={showAddEditCourierDialog}
                        >
                            <AddIcon />
                        </Fab>
                    </Grid>
                </Grid>
            }
        >
            <JumboScrollbar
                autoHeight={true}
                autoHideTimeout={4000}
                autoHeightMin={690}
                autoHide={true}
                hideTracksWhenNotNeeded
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell sx={{ pl: 3 }}>
                                Customer Name
                            </StyledTableCell>
                            <StyledTableCell>Mobile</StyledTableCell>
                            <StyledTableCell>To Address</StyledTableCell>
                            <StyledTableCell>To Name</StyledTableCell>
                            <StyledTableCell>Tracking No.</StyledTableCell>
                            <StyledTableCell>UPS No.</StyledTableCell>
                            <StyledTableCell>Amount</StyledTableCell>
                            <StyledTableCell>Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {courierList?.map((courier) => (
                            <CourierListItem
                                item={courier}
                                key={courier.id}
                                handleItemClick={() => {
                                    showAddEditCourierDialog("", courier);
                                }}
                                handleRemoveCourier={handleRemoveCourier}
                            />
                        ))}
                    </TableBody>
                </Table>
            </JumboScrollbar>
            <Snackbar
                open={alertOpen}
                onClose={handleAlertClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert
                    onClose={handleAlertClose}
                    severity={alertType}
                    sx={{ width: "100%" }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
        </JumboCardQuick>
    );
};

export default ListCourier;
