import React from "react";
import ListExpenseCategory from "app/pages/expenseCategory/ListExpenseCategory";
import Page from "@jumbo/shared/Page";

const expenseCategoryRoutes = [
    {
        path: "/expenseCategory/list",
        element: <Page component={ListExpenseCategory} />,
    },
];

export default expenseCategoryRoutes;
