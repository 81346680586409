import jwtAuthAxios from "./auth/jwtAuth";

const expenseCategoryService = {};

expenseCategoryService.getList = async () => {
    const {data} = await jwtAuthAxios.post("/index.php?action=listExpenseCategory");
    return data;
};
expenseCategoryService.add = async (payload) => {
    const {data} = await jwtAuthAxios.post("/index.php?action=addExpenseCategory", payload);
    return data;
};
expenseCategoryService.update = async (payload) => {
    const {data} = await jwtAuthAxios.post("/index.php?action=updateExpenseCategory", payload);
    return data;
};
expenseCategoryService.remove = async (payload) => {
    const {data} = await jwtAuthAxios.post("/index.php?action=removeExpenseCategory", payload);
    return data;
};

export {expenseCategoryService};
