import React from 'react';

const Dashboard = () => {
    return (
        <div>
            <h2>Sample blank dashboard</h2>
            {/* <p>This page is just to showcase the way you can add your own pages.</p>
            <p>Happy Coding!</p> */}
        </div>
    );
};

export default Dashboard;