import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import JumboAutoCompleteField from "@jumbo/components/JumboFormik/JumboAutoCompleteField";
import Div from "@jumbo/shared/Div";
import { staffService } from "app/services/staff-service";
import { branchService } from "app/services/branch-service";
import { designationService } from "app/services/designation-service";
import { formatPayload } from "app/utils/staffUtils";

const validationSchema = yup.object({
    name: yup.string("Enter staff name").required("Name is required"),
});
const initialValues = {
    name: "",
    email: "",
    mobile: "",
    whatsapp: "",
    password: "",
    type: "staff",
    branch_id: null,
    salary: "",
    designation_id: null,
    address: "",
    civil_id: "",
    civil_id_expiry: "",
    passport: "",
    passport_expiry: "",
    last_vacation_from: "",
    last_vacation_to: "",
    is_active: true,
    role: {
        dashboardTile1View: 1,
        enquiryListView: 1,
        enquiryCreate: 1,
        enquiryEdit: 1,
        enquiryDelete: 1,
        purposeListView: 1,
        purposeCreate: 1,
        purposeEdit: 1,
        purposeDelete: 1,
        customersListView: 1,
        customersCreate: 1,
        customersEdit: 1,
        customersDelete: 1,
        expensesListView: 1,
        expensesCreate: 1,
        expensesEdit: 1,
        expensesDelete: 1,
        expenseCategoryListView: 1,
        expenseCategoryCreate: 1,
        expenseCategoryEdit: 1,
        expenseCategoryDelete: 1,
        staffListView: 1,
        staffCreate: 1,
        staffEdit: 1,
        staffDelete: 1,
        designationsListView: 1,
        designationsCreate: 1,
        designationsEdit: 1,
        designationsDelete: 1,
        salaryBookListView: 1,
        salaryBookCreate: 1,
        salaryBookEdit: 1,
        salaryBookDelete: 1,
        branchesListView: 1,
        branchesCreate: 1,
        branchesEdit: 1,
        branchesDelete: 1,
        daybookListView: 1,
        courierListView: 1,
        courierCreate: 1,
        courierEdit: 1,
        courierDelete: 1,
    },
};

const StaffForm = ({ staff, onSave }) => {
    const [branchList, setBranchList] = useState([]);
    const [designationList, setDesignationList] = useState([]);
    const formikRef = useRef();

    const onStaffSave = (data, { setSubmitting }) => {
        setSubmitting(true);

        if (staff?.id) {
            staffService.update(formatPayload(data)).then((result) => {
                if (result?.status) {
                    onSave(staff?.id, "edit");
                } else {
                    console.log("Staff updating failed ", result);
                }
                setSubmitting(false);
            });
        } else {
            staffService.add(formatPayload(data)).then((result) => {
                if (result?.status) {
                    onSave(result?.id);
                } else {
                    console.log("Staff adding failed ", result);
                }
                setSubmitting(false);
            });
        }
    };

    useEffect(() => {
        // if is in edit mode?
        if (staff?.id) {
            // once designationList loaded
            if (designationList?.length) {
                if (formikRef.current) {
                    //update branch_id field with object
                    formikRef.current.setFieldValue(
                        "branch_id",
                        branchList?.find(
                            (item) => item?.id === staff?.branch_id
                        )
                    );
                    //update designation_id field with object
                    formikRef.current.setFieldValue(
                        "designation_id",
                        designationList?.find(
                            (item) => item?.id === staff?.designation_id
                        )
                    );
                    //update role field if not exist
                    //@todo handle this role
                    if (!staff?.role) {
                        formikRef.current.setFieldValue(
                            "role",
                            initialValues?.role
                        );
                    }
                }
            }
        }
    }, [branchList, designationList, staff]);

    useEffect(() => {
        branchService.getList().then((data) => {
            setBranchList(data?.branches);
            designationService.getList().then((data) => {
                setDesignationList(data?.designations);
            });
        });
    }, []);

    return (
        <Formik
            validateOnChange={true}
            validationSchema={validationSchema}
            initialValues={staff?.id ? staff : initialValues}
            enableReinitialize={true}
            onSubmit={onStaffSave}
            innerRef={formikRef}
        >
            {({ isSubmitting, setFieldValue, values }) => (
                <Form noValidate autoComplete="off">
                    <Div
                        sx={{
                            "& .MuiTextField-root": {
                                mb: 3,
                            },
                        }}
                    >
                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="name"
                            label="Name"
                        />
                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="email"
                            label="Email"
                        />
                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="mobile"
                            label="Mobile"
                        />
                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="whatsapp"
                            label="Whatsapp"
                        />
                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="password"
                            label="Password"
                        />
                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="salary"
                            label="Salary"
                        />
                        <JumboAutoCompleteField
                            fullWidth
                            size="small"
                            name="branch_id"
                            label="Branch"
                            options={branchList}
                            optionallabel={"name"}
                            value={values?.branch_id || null}
                            onChange={(e, value) => {
                                if (value) {
                                    setFieldValue("branch_id", value);
                                }
                            }}
                        />
                        <JumboAutoCompleteField
                            fullWidth
                            size="small"
                            name="designation_id"
                            label="Designation"
                            options={designationList}
                            optionallabel={"name"}
                            value={values?.designation_id || null}
                            onChange={(e, value) => {
                                if (value) {
                                    setFieldValue("designation_id", value);
                                }
                            }}
                        />
                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="address"
                            label="Address"
                        />
                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="civil_id"
                            label="Civil ID"
                        />
                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="civil_id_expiry"
                            label="Civil ID Expiry"
                        />
                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="passport"
                            label="Passport"
                        />
                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="passport_expiry"
                            label="Passport Expiry"
                        />
                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="last_vacation_from"
                            label="Last Vacation From"
                        />
                        <JumboTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="last_vacation_to"
                            label="last Vacation To"
                        />
                        <LoadingButton
                            fullWidth
                            type="submit"
                            variant="contained"
                            size="large"
                            sx={{ mb: 3 }}
                            loading={isSubmitting}
                        >
                            Save
                        </LoadingButton>
                    </Div>
                </Form>
            )}
        </Formik>
    );
};
StaffForm.defaultProps = {
    onSave: () => {},
};
export default StaffForm;
