import React from "react";
import ListStaff from "app/pages/staff/ListStaff";
import Page from "@jumbo/shared/Page";

const staffRoutes = [
    {
        path: "/staff/list",
        element: <Page component={ListStaff} />,
    },
];

export default staffRoutes;
