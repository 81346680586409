import jwtAuthAxios from "./auth/jwtAuth";

const enquiryService = {};

enquiryService.getList = async (payload) => {
    const { data } = await jwtAuthAxios.post(
        "/index.php?action=listEnquiry",
        payload
    );
    return data;
};
enquiryService.viewEnquiry = async (payload) => {
    const { data } = await jwtAuthAxios.post(
        "/index.php?action=viewEnquiry",
        payload
    );
    return data;
};
enquiryService.addEnquiry = async (payload) => {
    const { data } = await jwtAuthAxios.post(
        "/index.php?action=addEnquiry",
        payload
    );
    return data;
};

enquiryService.updateEnquiry = async (payload) => {
    const { data } = await jwtAuthAxios.post(
        "/index.php?action=updateEnquiry",
        payload
    );
    return data;
};
export { enquiryService };
