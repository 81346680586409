import React from "react";
import ListDesignation from "app/pages/designation/ListDesignation";
import Page from "@jumbo/shared/Page";

const designationRoutes = [
    {
        path: "/designation/list",
        element: <Page component={ListDesignation} />,
    },
];

export default designationRoutes;
