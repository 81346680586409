import jwtAuthAxios from "./auth/jwtAuth";

const courierService = {};

courierService.getList = async (payload) => {
    const { data } = await jwtAuthAxios.post(
        "/index.php?action=listCourier",
        payload
    );
    return data;
};
courierService.add = async (payload) => {
    const { data } = await jwtAuthAxios.post(
        "/index.php?action=addCourier",
        payload
    );
    return data;
};
courierService.update = async (payload) => {
    const { data } = await jwtAuthAxios.post(
        "/index.php?action=updateCourier",
        payload
    );
    return data;
};
courierService.remove = async (payload) => {
    const { data } = await jwtAuthAxios.post(
        "/index.php?action=removeCourier",
        payload
    );
    return data;
};

export { courierService };
