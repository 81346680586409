import jwtAuthAxios from "./auth/jwtAuth";

const designationService = {};

designationService.getList = async () => {
    const {data} = await jwtAuthAxios.post("/index.php?action=listDesignation");
    return data;
};
designationService.add = async (payload) => {
    const {data} = await jwtAuthAxios.post("/index.php?action=addDesignation", payload);
    return data;
};
designationService.update = async (payload) => {
    const {data} = await jwtAuthAxios.post("/index.php?action=updateDesignation", payload);
    return data;
};
designationService.remove = async (payload) => {
    const {data} = await jwtAuthAxios.post("/index.php?action=removeDesignation", payload);
    return data;
};

export {designationService};
