import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import PurposeListItem from "./PurposeListItem";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import styled from "@emotion/styled";
import { purposeService } from "app/services/purpose-service";
import PurposeForm from "./PurposeForm";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: "none",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant="filled"
            {...props}
            sx={{ zIndex: 20000 }}
        />
    );
});

const parsePurposeData = (data) => {
    const parsedData = data?.map((item) => {
        let new_obj = { ...item, external: JSON.parse(item?.external_fee) };

        //replace null values with ""
        new_obj.external = new_obj.external?.map((external_item) => {
            return {
                fee: external_item?.fee || "",
                remarks: external_item?.remarks || "",
            };
        });

        delete new_obj.external_fee;
        return new_obj;
    });
    return parsedData;
};

const ListPurpose = () => {
    const { t } = useTranslation();
    const [purposeList, setPurposeList] = useState([]);
    const { hideDialog, showDialog } = useJumboDialog();
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");

    useEffect(() => {
        purposeService.getList().then((data) => {
            setPurposeList(parsePurposeData(data?.purposes));
        });
    }, []);

    const hideDialogAndRefreshPurposeList = React.useCallback(() => {
        //fetch updated purpose list
        purposeService.getList().then((data) => {
            setPurposeList(parsePurposeData(data?.purposes));
            setAlertType("success");
            setAlertMessage("Purpose Removed Successfully !");
            setAlertOpen(true);
            hideDialog();
        });
    }, [hideDialog]);

    const handleRemovePurpose = (purposeId) => {
        showDialog({
            variant: "confirm",
            title: "Are you sure about deleting this purpose?",
            content: "You won't be able to recover this purpose later",
            onYes: () =>
                purposeService.remove({ id: purposeId }).then(() => {
                    hideDialogAndRefreshPurposeList();
                }),
            onNo: hideDialog,
        });
    };

    const handleAlertClose = (event, reason) => {
        setAlertOpen(false);
    };

    const handlePurposeAdd = React.useCallback(
        (purposeId, mode) => {
            if (mode === "edit") {
                setAlertType("success");
                setAlertMessage("Purpose Udpated Successfully !");
            } else {
                setAlertType("success");
                setAlertMessage("Purpose Added Successfully !");
            }
            setAlertOpen(true);

            //fetch updated purpose list
            purposeService.getList().then((data) => {
                setPurposeList(parsePurposeData(data?.purposes));
                hideDialog();
            });
        },
        [hideDialog]
    );

    const showAddEditPurposeDialog = React.useCallback(
        (event, purpose) => {
            if (purpose) {
                showDialog({
                    title: "Update Purpose",
                    content: (
                        <PurposeForm
                            onSave={handlePurposeAdd}
                            purpose={purpose}
                        />
                    ),
                });
            } else {
                showDialog({
                    title: "Add New Purpose",
                    content: <PurposeForm onSave={handlePurposeAdd} />,
                });
            }
        },
        [handlePurposeAdd, showDialog]
    );

    return (
        <JumboCardQuick
            title={t("pages.title.purposeList")}
            wrapperSx={{ p: 0 }}
            action={
                <Fab
                    size="small"
                    color="primary"
                    aria-label="add"
                    onClick={showAddEditPurposeDialog}
                >
                    <AddIcon />
                </Fab>
            }
        >
            <JumboScrollbar
                autoHeight={true}
                autoHideTimeout={4000}
                autoHeightMin={690}
                autoHide={true}
                hideTracksWhenNotNeeded
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell sx={{ pl: 3 }}>
                                Name
                            </StyledTableCell>
                            <StyledTableCell>
                                Classic Fee
                            </StyledTableCell>
                            <StyledTableCell>
                                External Fee
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                Action
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {purposeList?.map((purpose) => (
                            <PurposeListItem
                                item={purpose}
                                key={purpose.id}
                                handleItemClick={() => {
                                    showAddEditPurposeDialog("", purpose);
                                }}
                                handleRemovePurpose={handleRemovePurpose}
                            />
                        ))}
                    </TableBody>
                </Table>
            </JumboScrollbar>
            <Snackbar
                open={alertOpen}
                onClose={handleAlertClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert
                    onClose={handleAlertClose}
                    severity={alertType}
                    sx={{ width: "100%" }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
        </JumboCardQuick>
    );
};

export default ListPurpose;
