import React from "react";
import ListCourier from "app/pages/courier/ListCourier";
import Page from "@jumbo/shared/Page";

const courierRoutes = [
    {
        path: "/courier/list",
        element: <Page component={ListCourier} />,
    },
];

export default courierRoutes;
