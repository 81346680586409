import React, { useState, useEffect, useRef } from "react";
import { Form, Formik, FieldArray } from "formik";
import JumboAutoCompleteField from "@jumbo/components/JumboFormik/JumboAutoCompleteField";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Div from "@jumbo/shared/Div";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { TextField, Typography, Tooltip } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import * as yup from "yup";
import { customerService } from "app/services/customer-service";
import { enquiryService } from "app/services/enquiry-service";
import { purposeService } from "app/services/purpose-service";
import {
    formatEnquiryPayload,
    calculateTotalPayment,
    dateDiffInDays,
} from "app/utils/enquiryUtils";
import CustomerForm from "../customer/CustomerForm";
import { useParams } from "react-router-dom";

const initialValues = {
    customer_id: null,
    purpose_details: [
        {
            id: null,
            purpose: null,
            for_self: null,
            civil_id: "",
            other_person_name: "",
            passport_no: "",
            remarks: "",
            status: "Processing",
            scheduledToCompleteAfter: "",
        },
    ],
    payment_status: null,
    payment_method: null,
    paid_amount: "",
    balance_amount: 0,
};

const validationSchema = yup.object({
    customer_id: yup.string().required("Customer Name is required").nullable(),
    purpose_details: yup.array().of(
        yup.object({
            purpose: yup.object().required("Purpose is required"),
            for_self: yup.string().required("For Self is required"),
            // civil_id: yup.string().required('Civil ID is required'),
        })
    ),
    // email: yup
    //     .string("Enter customer email")
    //     .email("Enter a valid email")
    //     .required("Email is required"),
    // mobile: yup.string("Enter customer mobile").required("Mobile is required"),
});

const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant="filled"
            {...props}
            sx={{ zIndex: 2000 }}
        />
    );
});

const AddEnquiry = () => {
    const [customer, setCustomer] = useState(null);
    const [customerList, setCustomerList] = useState([]);
    const [purposeList, setPurposeList] = useState([]);
    const authData = useJumboAuth();
    const { hideDialog, showDialog } = useJumboDialog();
    const formikRef = useRef();
    const arrayHelpersRef = useRef(null);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [enquiryStatus, setEnquiryStatus] = useState("new");
    const [enquiry, setEnquiry] = useState({ id: null });
    const { id } = useParams();

    const handleAlertClose = (event, reason) => {
        setAlertOpen(false);
    };

    const onSaveEnquiry = (data, { setSubmitting }) => {
        data.staff_user_id = authData?.authUser?.user?.id;
        data.branch_id = authData?.authUser?.user?.branch_id;
        if (enquiryStatus === "new") {
            enquiryService
                .addEnquiry(formatEnquiryPayload(data))
                .then((result) => {
                    console.log(result);
                    setSubmitting(false);
                    if (result?.status && result?.status === true) {
                        setAlertType("success");
                        setAlertMessage("Enquiry Added Successfully !");
                        setEnquiry({
                            id: result?.id,
                            ...data,
                        });
                        setEnquiryStatus("edit");
                    } else {
                        setAlertType("error");
                        setAlertMessage("Enquiry Create failed !");
                    }
                    setAlertOpen(true);
                });
        } else {
            //@todo - when updating enquiry, cutomer_id, branch_id need not pass. Pass enquiry_id
            data.enquiry_id = enquiry.id;
            data.action = "Update"; //@todo make it dynamic based on data changed
            enquiryService
                .updateEnquiry(formatEnquiryPayload(data))
                .then((result) => {
                    console.log(result);
                    setSubmitting(false);
                    if (result?.status && result?.status === true) {
                        setAlertType("success");
                        setAlertMessage("Enquiry Updated Successfully !");
                        setEnquiryStatus("edit");
                    } else {
                        setAlertType("error");
                        setAlertMessage("Enquiry Update failed !");
                    }
                    setAlertOpen(true);
                });
        }
    };

    const handleCustomerAdd = React.useCallback(
        (customerId) => {
            //fetch updated customer list
            customerService.getList().then((data) => {
                setCustomerList(data?.customers);
                const newCustomer = data?.customers?.find(
                    (customer) => customer.id == customerId
                );
                setCustomer(newCustomer);
                if (formikRef.current) {
                    formikRef.current.setFieldValue("customer_id", customerId);
                }
                hideDialog();
            });
        },
        [hideDialog]
    );

    const showAddEditCustomerDialog = React.useCallback(() => {
        if (customer?.id) {
            showDialog({
                title: "Update Customer",
                content: (
                    <CustomerForm
                        onSave={handleCustomerAdd}
                        customer={customer}
                    />
                ),
            });
        } else {
            showDialog({
                title: "Add New Customer",
                content: <CustomerForm onSave={handleCustomerAdd} />,
            });
        }
    }, [handleCustomerAdd, showDialog, customer]);

    const formatPurposeDetailsData = (purpose_details) => {
        if (purposeList?.length) {
            const formattedList = purpose_details.map((element) => {
                const newElement = {};
                newElement.purpose = purposeList?.find(
                    (item) => item?.id === element?.purpose_id
                );
                newElement.id = element?.id;
                newElement.for_self = element?.for_self;
                newElement.civil_id = element?.civil_id;
                newElement.other_person_name = element?.other_person_name;
                newElement.passport_no = element?.passport_no;
                newElement.remarks = element?.remarks;
                newElement.status = element?.status;
                newElement.scheduledToCompleteAfter = dateDiffInDays(
                    new Date(element?.scheduled_to_complete_on)
                );
                return newElement;
            });
            return formattedList;
        }
    };

    useEffect(() => {
        let isMounted = true;

        //fetch customer list
        customerService.getList().then((customerListData) => {
            if (isMounted) {
                setCustomerList(customerListData?.customers);

                //fetch purpose list
                purposeService.getList().then((data) => {
                    setPurposeList(data?.purposes);
                });
            }
        });
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        // once purposeList loaded
        if (purposeList?.length) {
            // if ednquiry is in edit mode?
            if (id) {
                setEnquiryStatus("edit");
                enquiryService.viewEnquiry({ enquiry_id: id }).then((data) => {
                    setEnquiry(data?.enquiry);

                    //set customer details
                    if (formikRef.current) {
                        formikRef.current.setFieldValue(
                            "customer_id",
                            data?.enquiry?.customer_id
                        );
                        const selectedCustomer = customerList?.find(
                            (customer) =>
                                customer.id == data?.enquiry?.customer_id
                        );
                        setCustomer(selectedCustomer);

                        //set purpose details
                        formikRef.current.setFieldValue(
                            "purpose_details",
                            formatPurposeDetailsData(data?.purpose_details)
                        );

                        //set payment details
                        formikRef.current.setFieldValue(
                            "payment_status",
                            data?.enquiry?.payment_status
                        );
                        formikRef.current.setFieldValue(
                            "payment_method",
                            data?.enquiry?.payment_method
                        );
                        formikRef.current.setFieldValue(
                            "paid_amount",
                            data?.enquiry?.paid_amount
                        );
                        formikRef.current.setFieldValue(
                            "balance_amount",
                            data?.enquiry?.balance_amount
                        );
                    }
                });
            }
        }
    }, [purposeList]);

    return (
        <JumboCardQuick
            title={enquiryStatus === "new" ? "Add Enquery" : "Edit Enquiry"}
            headerSx={{ pb: 0 }}
            sx={{ mb: 3.75 }}
        >
            <Divider sx={{ mb: 2 }} />
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validateOnChange={true}
                validationSchema={validationSchema}
                onSubmit={onSaveEnquiry}
                innerRef={formikRef}
            >
                {({ setFieldValue, values, isSubmitting }) => (
                    <Form noValidate autoComplete="off">
                        <Div
                            sx={{
                                "& .MuiTextField-root": {
                                    mb: 3,
                                },
                            }}
                        >
                            <Grid
                                container
                                spacing={2}
                                mb={1}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-start"
                            >
                                <Grid item>
                                    <Typography
                                        variant="h4"
                                        fontWeight="300"
                                        mb={2}
                                    >
                                        Customer Details
                                    </Typography>
                                </Grid>
                                {(enquiryStatus === "new" ||
                                    (enquiryStatus === "edit" &&
                                        customer?.id)) && (
                                    <Grid item>
                                        <Tooltip
                                            title={
                                                customer?.id
                                                    ? "Edit Customer"
                                                    : "Add New Customer"
                                            }
                                        >
                                            <IconButton
                                                onClick={
                                                    showAddEditCustomerDialog
                                                }
                                            >
                                                {customer?.id ? (
                                                    <EditIcon />
                                                ) : (
                                                    <PersonAddAlt1Icon />
                                                )}
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                )}
                            </Grid>

                            <Grid container spacing={2} mb={1}>
                                <Grid item xs={4}>
                                    <JumboAutoCompleteField
                                        fullWidth
                                        size="small"
                                        name="customer_id"
                                        label="Name"
                                        options={customerList}
                                        optionallabel={"name"}
                                        value={customer}
                                        readOnly={enquiryStatus === "edit"}
                                        onChange={(e, value) => {
                                            if (value) {
                                                setFieldValue(
                                                    "customer_id",
                                                    value !== null
                                                        ? value?.id
                                                        : initialValues.customer_id
                                                );
                                                setCustomer(value);
                                            } else {
                                                setCustomer(null);
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        label="Mobile"
                                        value={customer?.mobile || ""}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        label="WhatsApp"
                                        value={customer?.whatsapp || ""}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        label="Email"
                                        value={customer?.email || ""}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        label="Remarks"
                                        value={customer?.remarks || ""}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Divider sx={{ mb: 2 }} />

                            <Grid
                                container
                                spacing={2}
                                mb={1}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-start"
                            >
                                <Grid item>
                                    <Typography
                                        variant="h4"
                                        fontWeight="300"
                                        mb={2}
                                    >
                                        Purpose Details
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Tooltip title="Add Purpose">
                                        <IconButton
                                            sx={{
                                                marginTop: "-10px!important",
                                            }}
                                            onClick={() => {
                                                arrayHelpersRef.current.push({
                                                    id: null,
                                                    purpose: null,
                                                    for_self: null,
                                                    civil_id: "",
                                                    other_person_name: "",
                                                    passport_no: "",
                                                    remarks: "",
                                                    status: "Processing",
                                                    scheduledToCompleteAfter:
                                                        "",
                                                });
                                            }}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>

                            <FieldArray
                                name="purpose_details"
                                render={(arrayHelpers) => {
                                    arrayHelpersRef.current = arrayHelpers;

                                    return (
                                        <>
                                            {values.purpose_details?.length >
                                                0 &&
                                                values.purpose_details?.map(
                                                    (purposeDetail, index) => (
                                                        <Grid
                                                            container
                                                            spacing={2}
                                                            mb={1}
                                                            key={index}
                                                        >
                                                            <Grid item xs={4}>
                                                                <JumboAutoCompleteField
                                                                    fullWidth
                                                                    size="small"
                                                                    name={`purpose_details.${index}.purpose`}
                                                                    label="Purpose"
                                                                    optionallabel="name"
                                                                    options={
                                                                        purposeList
                                                                    }
                                                                    value={
                                                                        purposeDetail?.purpose ||
                                                                        null
                                                                    }
                                                                    onChange={async (
                                                                        e,
                                                                        value
                                                                    ) => {
                                                                        await setFieldValue(
                                                                            `purpose_details.${index}.purpose`,
                                                                            value !==
                                                                                null
                                                                                ? value
                                                                                : initialValues
                                                                                      .purpose_details
                                                                                      .purpose
                                                                        );

                                                                        setFieldValue(
                                                                            "balance_amount",
                                                                            calculateTotalPayment(
                                                                                formikRef
                                                                                    .current
                                                                                    .values
                                                                                    .purpose_details
                                                                            ) -
                                                                                formikRef
                                                                                    .current
                                                                                    .values
                                                                                    .paid_amount
                                                                        );
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <JumboAutoCompleteField
                                                                    fullWidth
                                                                    size="small"
                                                                    name={`purpose_details.${index}.for_self`}
                                                                    label="For"
                                                                    options={[
                                                                        "Self",
                                                                        "Other Person",
                                                                    ]}
                                                                    value={
                                                                        purposeDetail?.for_self ||
                                                                        null
                                                                    }
                                                                    onChange={(
                                                                        e,
                                                                        value
                                                                    ) => {
                                                                        setFieldValue(
                                                                            `purpose_details.${index}.for_self`,
                                                                            value !==
                                                                                null
                                                                                ? value
                                                                                : initialValues
                                                                                      .purpose_details
                                                                                      .for_self
                                                                        );
                                                                    }}
                                                                />
                                                            </Grid>
                                                            {(purposeDetail?.for_self ==
                                                                "Other Person" ||
                                                                (purposeDetail
                                                                    ?.purpose
                                                                    ?.name ==
                                                                    "Civil ID" &&
                                                                    purposeDetail?.for_self ==
                                                                        "Self")) && (
                                                                <Grid
                                                                    item
                                                                    xs={2}
                                                                >
                                                                    <JumboTextField
                                                                        fullWidth
                                                                        size="small"
                                                                        name={`purpose_details.${index}.civil_id`}
                                                                        label="Civil ID"
                                                                    />
                                                                </Grid>
                                                            )}

                                                            {purposeDetail?.for_self ==
                                                                "Other Person" && (
                                                                <>
                                                                    <Grid
                                                                        item
                                                                        xs={2}
                                                                    >
                                                                        <JumboTextField
                                                                            fullWidth
                                                                            size="small"
                                                                            name={`purpose_details.${index}.other_person_name`}
                                                                            label="Other Person Name"
                                                                        />
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={2}
                                                                    >
                                                                        <JumboTextField
                                                                            fullWidth
                                                                            size="small"
                                                                            name={`purpose_details.${index}.passport_no`}
                                                                            label="Other Person Passport"
                                                                        />
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={2}
                                                                    >
                                                                        <JumboTextField
                                                                            fullWidth
                                                                            size="small"
                                                                            name={`purpose_details.${index}.remarks`}
                                                                            label="Other Person Remarks"
                                                                        />
                                                                    </Grid>
                                                                </>
                                                            )}

                                                            <Grid item xs={2}>
                                                                <JumboAutoCompleteField
                                                                    fullWidth
                                                                    size="small"
                                                                    name={`purpose_details.${index}.status`}
                                                                    label="Status"
                                                                    options={[
                                                                        "Processing",
                                                                        "Completed",
                                                                        "Cancelled",
                                                                        "Scheduled to Complete After x days",
                                                                    ]}
                                                                    value={
                                                                        purposeDetail?.status ||
                                                                        null
                                                                    }
                                                                    onChange={(
                                                                        e,
                                                                        value
                                                                    ) => {
                                                                        setFieldValue(
                                                                            `purpose_details.${index}.status`,
                                                                            value !==
                                                                                null
                                                                                ? value
                                                                                : initialValues
                                                                                      .purpose_details
                                                                                      .status
                                                                        );
                                                                    }}
                                                                />
                                                            </Grid>
                                                            {purposeDetail?.status ==
                                                                "Scheduled to Complete After x days" && (
                                                                <Grid
                                                                    item
                                                                    xs={2}
                                                                >
                                                                    <JumboTextField
                                                                        fullWidth
                                                                        size="small"
                                                                        type="number"
                                                                        name={`purpose_details.${index}.scheduledToCompleteAfter`}
                                                                        label="Days"
                                                                    />
                                                                </Grid>
                                                            )}
                                                            {values
                                                                .purpose_details
                                                                ?.length >
                                                                1 && (
                                                                <Grid
                                                                    item
                                                                    xs={1}
                                                                >
                                                                    <Tooltip title="Remove Purpose">
                                                                        <IconButton
                                                                            onClick={() =>
                                                                                arrayHelpers.remove(
                                                                                    index
                                                                                )
                                                                            }
                                                                        >
                                                                            <CloseIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    )
                                                )}
                                        </>
                                    );
                                }}
                            />
                            <Divider sx={{ mb: 2 }} />
                            <Typography variant="h4" fontWeight="300" mb={2}>
                                Payment Details
                            </Typography>

                            <Grid container spacing={2} mb={3}>
                                <Grid item xs={4}>
                                    <JumboAutoCompleteField
                                        fullWidth
                                        size="small"
                                        name="payment_status"
                                        label="Payment Status"
                                        options={[
                                            "Paid",
                                            "Partially Paid",
                                            "Not Paid",
                                        ]}
                                        value={
                                            values?.payment_status ||
                                            enquiry?.payment_status ||
                                            null
                                        }
                                        onChange={(e, value) => {
                                            setFieldValue(
                                                "payment_status",
                                                value !== null
                                                    ? value
                                                    : initialValues.payment_status
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <JumboTextField
                                        fullWidth
                                        size="small"
                                        type="number"
                                        name="paid_amount"
                                        label="Paid Amount"
                                        onBlur={() => {
                                            setFieldValue(
                                                "balance_amount",
                                                calculateTotalPayment(
                                                    formikRef.current.values
                                                        .purpose_details
                                                ) -
                                                    formikRef.current.values
                                                        .paid_amount
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <JumboTextField
                                        fullWidth
                                        size="small"
                                        type="number"
                                        name="balance_amount"
                                        label="Balance Amount"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <JumboAutoCompleteField
                                        fullWidth
                                        size="small"
                                        name="payment_method"
                                        label="Payment Method"
                                        options={["KNet", "Cash", "Cheque"]}
                                        value={
                                            values?.payment_method ||
                                            enquiry?.payment_method ||
                                            null
                                        }
                                        onChange={(e, value) => {
                                            setFieldValue(
                                                "payment_method",
                                                value !== null
                                                    ? value
                                                    : initialValues.payment_method
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-start"
                                spacing={2}
                            >
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        {enquiryStatus === "new"
                                            ? "Submit"
                                            : "Update Enquiry"}
                                    </Button>
                                </Grid>
                                {enquiryStatus === "new" && (
                                    <>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                disabled={isSubmitting}
                                            >
                                                Submit and Print
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                disabled={isSubmitting}
                                            >
                                                Custom Print
                                            </Button>
                                        </Grid>
                                    </>
                                )}
                            </Grid>

                            {/* <Typography variant="h4" fontWeight="300" mb={2}>
                                Enquiry History
                            </Typography> */}
                        </Div>
                    </Form>
                )}
            </Formik>
            <Snackbar
                open={alertOpen}
                onClose={handleAlertClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert
                    onClose={handleAlertClose}
                    severity={alertType}
                    sx={{ width: "100%" }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
        </JumboCardQuick>
    );
};

export default AddEnquiry;
