import jwtAuthAxios from "./auth/jwtAuth";

const branchService = {};

branchService.getList = async () => {
    const {data} = await jwtAuthAxios.post("/index.php?action=listBranch");
    return data;
};
branchService.add = async (payload) => {
    const {data} = await jwtAuthAxios.post("/index.php?action=addBranch", payload);
    return data;
};
branchService.update = async (payload) => {
    const {data} = await jwtAuthAxios.post("/index.php?action=updateBranch", payload);
    return data;
};
branchService.remove = async (payload) => {
    const {data} = await jwtAuthAxios.post("/index.php?action=removeBranch", payload);
    return data;
};

export {branchService};
