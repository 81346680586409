import React from "react";
import { Navigate } from "react-router-dom";
import Home from "../pages/home";
import Page from "@jumbo/shared/Page";
import Login2 from "../pages/auth-pages/login2";
import dashboardRoutes from "./dashboardRoutes";
import enquiryRoutes from "./enquiryRoutes";
import customerRoutes from "./customerRoutes";
import expenseRoutes from "./expenseRoutes";
import expenseCategoryRoutes from "./expenseCategoryRoutes";
import courierRoutes from "./courierRoutes";
import purposeRoutes from "./purposeRoutes";
import branchRoutes from "./branchRoutes";
import staffRoutes from "./staffRoutes";
import designationRoutes from "./designationRoutes";

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [
  {
    path: "/",
    element: <Page component={Home} />,
  },
];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
  {
    path: "/",
    element: <Navigate to={"/dashboards"} />,
  },
  ...dashboardRoutes,
  ...enquiryRoutes,
  ...customerRoutes,
  ...expenseRoutes,
  ...expenseCategoryRoutes,
  ...courierRoutes,
  ...purposeRoutes,
  ...branchRoutes,
  ...staffRoutes,
  ...designationRoutes,
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
  {
    path: "/user/login",
    element: (
      <Page component={Login2} layout={"solo-page"} disableSmLogin={true} />
    ),
  },
];

const routes = [
  ...routesForPublic,
  ...routesForAuthenticatedOnly,
  ...routesForNotAuthenticatedOnly,
];

export {
  routes as default,
  routesForPublic,
  routesForNotAuthenticatedOnly,
  routesForAuthenticatedOnly,
};
